import React, { useEffect } from "react";
import "tinymce/tinymce.min.js"; // Importa o núcleo do TinyMCE
import "tinymce/themes/silver/theme"; // Importa o tema
import tinymce from "tinymce";

const TinyMceEditor = () => {
  useEffect(() => {
    // Configuração do TinyMCE
    tinymce.init({
      selector: "#editor", // ID do elemento que será transformado no editor
      plugins: "paste link table code", // Configuração dos plugins como string
      toolbar:
        "undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | code",
      height: 400,
      content_css: "https://cdn.jsdelivr.net/npm/tinymce@6/skins/ui/oxide/skin.min.css", 
    //   base_url: "/tinymce", // Caminho base para os arquivos do TinyMCE
    //   suffix: ".min", // Usa os arquivos minificados
    });

    // Cleanup para evitar múltiplas inicializações ao desmontar o componente
    return () => {
      tinymce.remove("#editor");
    };
  }, []); // Executa apenas uma vez ao montar o componente

  return (
    <div>
      <textarea id="editor"></textarea> {/* ID usado no selector */}
    </div>
  );
};

export default TinyMceEditor;
