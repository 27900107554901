// src/pages/Home.tsx
import React from 'react';
//import SliderComponent from '../components/SliderComponent';
import InstituicoesParticipantes from '../imagens/InstituicoesParticipantes';

const Home: React.FC = () => {
  return (
    <div>
      <InstituicoesParticipantes/>
    </div>
  );
};

export default Home;
