// src/components/Footer.tsx
import { Box, Text } from '@chakra-ui/react';
import buildInfo from '../../build-info.json';

const Footer: React.FC = () => {
  return (
    <Box
      as="footer"
      bg="primary"
      color="white"
      p={4}
      position="fixed"
      bottom={0}
      width="100%"
      display="flex"
      alignItems="center"
    >
      {/* Espaço vazio para empurrar o texto do meio */}
      <Box flex="1" />

      {/* Texto centralizado */}
      <Text fontSize="sm" textAlign="center">© 2024 Ferramenta de Análise de Texto</Text>

      {/* Texto alinhado à direita */}
      <Box flex="1" textAlign="right">
        <Text fontSize="sm">Compilação: 1.0.{buildInfo.buildNumber}</Text>
      </Box>
    </Box>

  );
};

export default Footer;
