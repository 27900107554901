// import api from "./axios";

// const fetchData = async (
//   url: string,
//   method: 'get' | 'post' | 'put' | 'patch' | 'delete',
//   toast: any,
//   data?: any
// ) => {
//   try {
//     // Fazer a requisição usando a instância `api`
//     const response = await api({
//       url,
//       method,
//       ...(data ? { data } : {}), // Inclui os dados no corpo da requisição, se houver
//     });

//     if (response.status >= 200 && response.status < 300) {
//       // Mensagens de sucesso para POST, PUT, PATCH e DELETE
//       if (method === 'post') {
//         toast({
//           title: 'Criado com sucesso',
//           description: 'Os dados foram criados corretamente.',
//           status: 'success',
//         });
//       } else if (method === 'put') {
//         toast({
//           title: 'Substituído com sucesso',
//           description: 'Os dados foram substituídos corretamente.',
//           status: 'success',
//         });
//       } else if (method === 'patch') {
//         toast({
//           title: 'Atualizado com sucesso',
//           description: 'Os dados foram atualizados corretamente.',
//           status: 'success',
//         });
//       } else if (method === 'delete') {
//         toast({
//           title: 'Excluído com sucesso',
//           description: 'Os dados foram excluídos corretamente.',
//           status: 'success',
//         });
//       }
//       return response.data;
//     } else {
//       window.location.href = '/login';
//       return null;
//     }
//   } catch (err: any) {
//     // Tratamento de erros
//     if (err.response && err.response.status === 401) {
//       toast({
//         title: 'Sessão expirada',
//         description: 'Por favor, faça login novamente.',
//         status: 'error',
//       });
//       window.location.href = '/login';
//     } else {
//       console.error(`Erro ao fazer requisição para: ${url}`, err);
//       toast({
//         title: 'Erro na operação',
//         description: 'Ocorreu um erro durante a operação. Tente novamente.',
//         status: 'error',
//       });
//     }
//     return null;
//   }
// };

// export default fetchData;

import api from "./axios";
const fetchData = async (
  url: string,
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  toast: any,
  data?: any
) => {
  // Funções auxiliares para adicionar e remover o overlay
  const addOverlay = () => {
    const overlay = document.createElement('div');
    overlay.id = 'loading-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.zIndex = '9999';
    overlay.style.display = 'flex';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
  
    // Removendo o background preto
    overlay.style.pointerEvents = 'none'; // Garante que cliques não passem pelo overlay
  
    const spinner = document.createElement('div');
    spinner.style.border = '4px solid rgba(255, 255, 255, 0.3)';
    spinner.style.borderTop = '4px solid black'; // Cor visível no spinner
    spinner.style.borderRadius = '50%';
    spinner.style.width = '40px';
    spinner.style.height = '40px';
    spinner.style.animation = 'spin 1s linear infinite';
  
    overlay.appendChild(spinner);
    document.body.appendChild(overlay);
  
    // Adiciona a animação para o spinner
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(style);
  };
  

  const removeOverlay = () => {
    const overlay = document.getElementById('loading-overlay');
    if (overlay) {
      overlay.remove();
    }
  };

  try {
    // Adiciona o overlay no início da requisição
    addOverlay();

    // Fazer a requisição usando a instância `api`
    const response = await api({
      url,
      method,
      ...(data ? { data } : {}),
    });

    if (response.status >= 200 && response.status < 300) {
      // Mensagens de sucesso para POST, PUT, PATCH e DELETE
      if (method === 'post') {
        toast({
          title: 'Criado com sucesso',
          description: 'Os dados foram criados corretamente.',
          status: 'success',
        });
      } else if (method === 'put') {
        toast({
          title: 'Substituído com sucesso',
          description: 'Os dados foram substituídos corretamente.',
          status: 'success',
        });
      } else if (method === 'patch') {
        toast({
          title: 'Atualizado com sucesso',
          description: 'Os dados foram atualizados corretamente.',
          status: 'success',
        });
      } else if (method === 'delete') {
        toast({
          title: 'Excluído com sucesso',
          description: 'Os dados foram excluídos corretamente.',
          status: 'success',
        });
      }
      return response.data;
    } else {
      window.location.href = '/login';
      return null;
    }
  } catch (err: any) {
    // Tratamento de erros
    if (err.response && err.response.status === 401) {
      toast({
        title: 'Sessão expirada',
        description: 'Por favor, faça login novamente.',
        status: 'error',
      });
      window.location.href = '/login';
    } else {
      console.error(`Erro ao fazer requisição para: ${url}`, err);
      toast({
        title: 'Erro na operação',
        description: 'Ocorreu um erro durante a operação. Tente novamente.',
        status: 'error',
      });
    }
    return null;
  } finally {
    // Remove o overlay ao final da requisição
    removeOverlay();
  }
};

export default fetchData;
